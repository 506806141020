import React from 'react';
import './App.css'; // This imports the main stylesheet (you should include Tailwind CSS here)
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useState } from 'react';
import FullScreenImage from './components/workInProgress';
import MainComponent from './components/main';
import AllAthletes from './components/allAthletes';
import ContactSection from './components/Contact';
import Footer from './components/Footer';

const RedirectToStripe = () => {
    window.location.href = "https://buy.stripe.com/9AQ5my66xeca3S03cf"; // Replace with your external URL
    return null; // Ensure no UI is rendered
};

function App() {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/hidden" element={<FullScreenImage />} />
          <Route path="/" element={<MainComponent />} />
          <Route path="/athletes" element={<><AllAthletes /><ContactSection></ContactSection> <Footer></Footer></>} />
          <Route exact path="/ladyjays/give" element={<RedirectToStripe />} />
          <Route exact path="/ladyjays" element={<RedirectToStripe />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
